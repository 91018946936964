/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Noto Sans", sans-serif;
  color: #444444;
}

a {
  color: #106eea;
}

a:hover {
  color: #3b8af2;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

.fa-color {
  color: #4d1a00;
}
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #106eea;
  border-top-color: #e2eefd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #4d1a00;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #ff9999;
  color: #fff;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  background: #4d1a00;
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
}

#topbar.topbar-scrolled {
  top: -40px;
}

#topbar .contact-info a {
  line-height: 1;
  color: #fff;
  transition: 0.3s;
}

#topbar .contact-info a:hover {
  text-decoration: underline;
}

#topbar .contact-info i {
  padding-right: 4px;
  margin-left: 15px;
}

#topbar .contact-info i:first-child {
  margin-left: 0;
}

#topbar .social-links a {
  color: rgba(255, 255, 255, 0.6);
  padding-left: 15px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

#topbar .social-links a:hover {
  color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #4d1a00;
  transition: all 0.5s;
  z-index: 997;
  padding: 25px 0;
  top: 40px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header.header-scrolled {
  top: 0;
  padding: 15px;
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  font-family: "Strait", sans-serif !important;
  color: #ffffff;
}

#header .logo a span {
  color: #ffffff;
}

#header .logo img {
  max-height: 40px;
}

@media (max-width: 992px) {
  #header {
    padding: 15px;
    top: 0;
  }
  #header .logo {
    font-size: 28px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
  /* margin: 0; */
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #ffffff;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 600;
  padding: 0 3px;
  font-family: "Open Sans", sans-serif;
}

.nav-menu a:hover:before,
.nav-menu li:hover > a:before,
.nav-menu .active > a:before {
  visibility: visible;
  width: 100%;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  color: #b3b3b3;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 26px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #062b5b;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
  color: #106eea;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #222222;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 68%;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
  height: 40%;
  width: 30%;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #222222;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

/* .mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #106eea;
  text-decoration: none;
} */

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #106eea;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

/* .mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
} */

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 50%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(9, 9, 9, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 75vh;
  background-color: #df9926;
  background-size: cover;
  position: relative;
  color: white;
}

#hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .container {
  position: relative;
  padding-top: 132px;
}

@media (max-width: 992px) {
  #hero .container {
    padding-top: 58px;
  }
}

#hero h1 {
  font-family: "Strait", sans-serif !important;
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
}

#hero h1 span {
  color: #ffffff;
}

#hero h2 {
  font-family: "Strait", sans-serif !important;
  color: #ffffff;
  margin: 5px 0 30px 0;
  font-size: 24px;
  font-weight: 400;
}

#hero .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  border: 2px solid #ffffff;
}

#hero .btn-get-started:hover {
  background: #ffbf80;
}

#hero .btn-watch-video {
  font-size: 16px;
  display: inline-block;
  padding: 10px 25px 8px 40px;
  transition: 0.5s;
  margin-left: 25px;
  color: #222222;
  position: relative;
  font-weight: 600;
}

#hero .btn-watch-video i {
  color: #106eea;
  font-size: 32px;
  position: absolute;
  left: 0;
  top: 7px;
  transition: 0.3s;
}

#hero .btn-watch-video:hover {
  color: #106eea;
}

#hero .btn-watch-video:hover i {
  color: #3b8af2;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero {
    height: 100vh;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .btn-get-started,
  #hero .btn-watch-video {
    font-size: 13px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f6f9fe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: #106eea;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
}

.section-title h3 span {
  color: #106eea;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: #f1f6fe;
  min-height: 40px;
  margin-top: 134px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 58px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/

.featured-services {
  background-color: #4da6ff;
  font-family: "Strait", sans-serif !important;
}

.featured-services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #4da6ff;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.featured-services .icon-box::before {
  content: "";
  position: absolute;
  background: #4da6ff;
  right: 0;
  left: 0;
  bottom: 0;
  top: 100%;
  transition: all 0.3s;
  z-index: -1;
}

.featured-services .icon-box:hover::before {
  background: #1a8cff;
  top: 0;
  border-radius: 0px;
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon i {
  font-size: 48px;
  line-height: 1;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.featured-services .title {
  font-family: "Strait", sans-serif !important;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #ffffff;
}

.featured-services .description {
  font-family: "Strait", sans-serif !important;
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.featured-services .icon-box:hover .title a,
.featured-services .icon-box:hover .description {
  color: #e6f2ff;
}

.featured-services .icon-box:hover .icon i {
  color: #e6f2ff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about h3 {
  font-family: "Strait", sans-serif !important;
  color: #000000;
}

.about .content h3 {
  font-family: "Strait", sans-serif !important;
  font-weight: 600;
  font-size: 26px;
}

.about p {
  font-family: "Strait", sans-serif !important;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 35px;
}

.about .content ul li:first-child {
  margin-top: 35px;
}

.about .content ul i {
  background: #fff;
  box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
  font-size: 24px;
  padding: 20px;
  margin-right: 15px;
  color: #106eea;
  border-radius: 50px;
}

.about .content ul h5 {
  font-size: 18px;
  color: #555555;
}

.about .content ul p {
  font-size: 15px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.aboutIcon {
  text-align: center;
  transition: all ease-in-out 0.3s;
  height: 20px;
}
/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 50px;
  display: block;
  background: none;
}

.skills .progress .skill {
  padding: 10px 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #222222;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e2eefd;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #7575a3;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #f1f6fe;
}

.counts .count-box i {
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #106eea;
  padding: 12px;
  color: #fff;
  border-radius: 50px;
  border: 5px solid #fff;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #062b5b;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}

.clients img:hover {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.serviceIcon {
  text-align: center;
  transition: all ease-in-out 0.3s;
}

.services {
  background: #dfd7d5;
}

#services h3 {
  font-family: "Strait", sans-serif !important;
}

.services .icon-box {
  text-align: center;
  padding: 0px 0px 80px 0px;
  transition: all ease-in-out 0.3s;
  background: #ffffff;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 100%;
  height: 320px;
  background: #f1f6fe;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
}

.services .icon-box .title {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
}

.services .icon-box .title p span {
  display: block;
  font-family: "Strait", sans-serif !important;
}

.services .icon-box .description {
  margin-top: 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
}

.services .icon-box .description p span {
  display: block;
  font-family: "Strait", sans-serif !important;
}

.services .icon-box a {
  color: #222222;
  transition: ease-in-out 0.3s;
  font-weight: 500;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
}

.services .icon-box:hover h4 a,
.services .icon-box:hover .icon i {
  color: #106eea;
}

.services .icon-box:hover .icon {
  border-color: #106eea;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
  font-family: "Strait", sans-serif !important;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
  font-family: "Strait", sans-serif !important;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
  font-family: "Strait", sans-serif !important;
}

.testimonials .owl-nav,
.testimonials .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.testimonials .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.testimonials .owl-dot.active {
  background-color: #106eea !important;
}

.carousel .control-dots {
  bottom: -10px !important;
}

@media (min-width: 1024px) {
  .testimonials {
    background-attachment: fixed;
  }
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 15px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #106eea;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
}

.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #222222;
}

.portfolio .portfolio-item .portfolio-info p {
  color: #555555;
  font-size: 14px;
  margin-bottom: 0;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #3c3c3c;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #106eea;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-item .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-links a:hover {
  color: #6ba7f5;
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 20px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 30px;
}

.portfolio .section-title h3 {
  font-family: "Strait", sans-serif !important;
  color: black;
}

.portfolio-details .portfolio-details-container {
  position: relative;
}

.portfolio-details .portfolio-details-carousel {
  position: relative;
  z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #106eea !important;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -70px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
  width: 50%;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0 0 0 0;
}

@media (max-width: 768px) {
  .portfolio-details .portfolio-description h2 {
    width: 100%;
  }
  .portfolio-details .portfolio-info {
    position: static;
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  padding: 60px 0;
  background: url("../img/teaminfo.jpg") no-repeat;
}

.team .section-title h3 {
  font-family: "Strait", sans-serif !important;
  color: #ffff;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(16, 110, 234, 0.15);
}

.team .member .member-img {
  padding-top: 10px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #222222;
  margin: 0 3px;
  padding-top: 7px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(16, 110, 234, 0.8);
  display: inline-block;
  transition: ease-in-out 0.3s;
  color: #fff;
}

.team .member .social a:hover {
  background: #3b8af2;
}

.team .member .social i {
  font-size: 18px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-family: "Strait", sans-serif !important;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #222222;
}

.team .member .member-info span {
  font-family: "Strait", sans-serif !important;
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #222222;
}

.team .member .member-info p {
  font-family: "Strait", sans-serif !important;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(109, 134, 104, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pricing .section-title h3 {
  font-family: "Strait", sans-serif !important;
  color: black;
}

.pricing .section-title h4 {
  font-family: "Strait", sans-serif !important;
  color: black;
}

.pricing .box h3 {
  font-family: "Strait", sans-serif !important;
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #4d6600;
  background: #d6d6c2;
}

.pricing .box h4 {
  font-size: 36px;
  color: #4d6600;
  font-weight: 600;
  font-family: "Strait", sans-serif !important;
  margin-bottom: 20px;
}

.pricing .box h6 {
  font-size: 20px;
  color: #4d6600;
  font-weight: 600;
  font-family: "Strait", sans-serif !important;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing .box h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing .box ul {
  padding: 0;
  list-style: none;
  color: #4d6600;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing .box ul li {
  padding-bottom: 16px;
}

.pricing .box ul i {
  color: #f2ffcc;
  font-size: 18px;
  padding-right: 4px;
}

.pricing .box ul .na {
  color: #4d6600;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #ffe6ff;
  text-align: center;
}

.pricing .btn-buy {
  background: #f2ffcc;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Strait", sans-serif !important;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: #f2ffcc;
}

.pricing .featured h3 {
  color: #4d6600;
  background: #d6d6c2;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #106eea;
  color: #fff;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq {
  padding: 60px 0;
}

.faq .section-title h3 {
  font-family: "Strait", sans-serif !important;
  color: black;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  padding: 0 0 20px 25px;
}

.faq .faq-list a {
  font-family: "Strait", sans-serif !important;
  display: block;
  position: relative;
  font-family: #4d1a00;
  font-size: 18px;
  font-weight: 500;
}

.faq .faq-list i {
  font-size: 18px;
  position: absolute;
  left: -25px;
  top: 6px;
}

.faq .faq-list p {
  font-family: "Strait", sans-serif !important;
  margin-bottom: 20px;
  font-size: 15px;
}

.faq .faq-list a.collapse {
  color: #4d1a00;
}

.faq .faq-list a.collapsed {
  color: #4d1a00;
}

.faq .faq-list a.collapsed:hover {
  color: #4d1a00;
}

.faq .faq-list a.collapsed i::before {
  content: "\e901" !important;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
  padding: 20px 0 30px 0;
}

.contact .section-title h3 {
  font-family: "Strait", sans-serif !important;
  color: #222222;
}

.contact .email-form h6 {
  font-family: "Strait", sans-serif !important;
  color: #222222;
}

.contact .info-box i {
  font-size: 32px;
  color: #106eea;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #b3d1fa;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #222222;
  font-weight: 700;
  margin: 10px 0;
  font-family: "Strait", sans-serif !important;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  font-family: "Strait", sans-serif !important;
}

.contact .email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
  padding: 30px;
}

.contact .email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .email-form .error-message {
  color: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 100;
}

.contact .email-form .error-message br + br {
  margin-top: 25px;
}

.contact .email-form .sent-message {
  color: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 200;
}

.contact .email-form .loading {
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .email-form input,
.contact .email-form textarea {
  border-radius: 20px;
  box-shadow: none;
  font-size: 14px;
}

.contact .email-form input:focus,
.contact .email-form textarea:focus {
  border-color: #80dfff;
}

.contact .email-form input {
  padding: 20px 15px;
  background-color: #e6f9ff;
}

.contact .email-form textarea {
  padding: 12px 15px;
  background-color: #e6f9ff;
}

.contact .email-form button[type="submit"] {
  background: #33ccff;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 20px;
}

.contact .email-form button[type="submit"]:hover {
  background: #0099cc;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff;
  padding: 0 0 30px 0;
  color: #444444;
  font-size: 14px;
  background: #f1f6fe;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #4da6ff;
  text-align: center;
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-family: "Strait", sans-serif !important;
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 24px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="email"]:focus {
  border: 0;
  border-color: #ffffff;
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #33ccff;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 24px 24px 0;
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #0099cc;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
  font-family: "Strait", sans-serif !important;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 24px;
  margin: 0 0 15px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact h3 span {
  color: #106eea;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #777777;
}

#footer .footer-top h4 {
  font-family: "Strait", sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top h3 {
  font-family: "Strait", sans-serif !important;
}

#footer .footer-top p {
  font-family: "Strait", sans-serif !important;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #106eea;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  font-family: "Strait", sans-serif !important;
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #106eea;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #106eea;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #3b8af2;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  font-family: "Strait", sans-serif !important;
  text-align: center;
  float: left;
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #444444;
}

@media (max-width: 768px) {
  #footer .copyright,
  #footer .credits {
    float: none;
    text-align: center;
    padding: 2px 0;
  }
}
