.top10 {
  margin-top: 10px;
}

.font20 {
  font-size: 20px;
}

.setwh {
  width: 100%;
  height: 100%;
}

.height50 {
  height: 50px;
}

.contactwh {
  border: 0;
  width: 100%;
  height: 430px;
}
